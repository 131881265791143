.card {
  border-radius: 10px;
  background-color: white;
  display: block;
  max-width: 30%;
  padding: 3rem;
  /* height: 60vh; */
  margin-top: 8%;
  margin-bottom: 8%;
  margin-left: auto;
  box-shadow: 3px 3px 30px 3px rgb(232, 232, 232);
  margin-right: auto;
}
#mainform label {
  display: flex;
  flex-direction: column;
  margin-left: 9px;
}
.input-field input :invalid {
  border: red;
}
.input-field ::placeholder {
  display: block;
}
.label {
  padding: 3px;
}
.input-field {
  padding: 13px;
  width: 80%;
  border: 1px rgb(161, 161, 161) solid;
  margin: 10px;
  border-radius: 3px;
}
.heading {
  font-size: 2rem;
  color: rgb(48, 48, 48);
  font-family: Arial, Helvetica, sans-serif;
}
.sub-heading {
  font-size: medium;
  font-weight: 300;
  color: rgb(105, 105, 105);
  margin: 13px;
}
.button-submit-success {
  margin: 3px;
  padding: 10px;
  border: none;
  background-color: rgba(16, 134, 0, 0.813);
  border-radius: 3px;
  color: white;
  padding-inline: 30px;
}
.button-submit-success:hover {
  background-color: rgb(16, 134, 1);
}
.button-submit-error {
  margin: 3px;
  padding: 10px;
  border: none;
  background-color: rgb(134, 1, 8, 0.851);
  border-radius: 3px;
  color: white;
  padding-inline: 30px;
}
.button-submit-error:hover {
  background-color: rgba(134, 1, 8, 1);
}
.fl {
  display: flex;
  width: 100%;
  /* margin: 13px; */
}
.in-label {
  margin: 10px;
  padding: 0px;
  /* background-color: red; */
}

@media only screen and (max-width: 600px) {
  .card {
    max-width: 100%;
  }
}
